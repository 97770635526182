import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/remote-config";

import Logo from "./images/logo.png";
import Facebook from "./images/facebook.png";
import Twitter from "./images/twitter.png";
import Pintrest from "./images/pintrest.png";
import Linkedin from "./images/linkedin.png";
import { Link } from "react-router-dom";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  projectId: process.env.FIREBASE_PROJECT_ID,
  appId: process.env.FIREBASE_APP_ID,
};

const Footer = () => {
  const [contactEmail, setContactEmail] = useState("");
  const [contactMobile, setContactMobile] = useState("");
  const [socialPages, setSocialPages] = useState(null);

  useEffect(() => {
    try {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);

      // get remote config
      const remoteConfig = firebase.remoteConfig();
      remoteConfig
        .fetchAndActivate()
        .then(() => {
          const contactEmail = remoteConfig.getValue("contact_email");
          const contactMobile = remoteConfig.getValue("contact_mobile");
          const socialPages = remoteConfig.getValue("social_pages");
          setContactEmail(contactEmail._value);
          setContactMobile(contactMobile._value);
          setSocialPages(JSON.parse(socialPages._value));
        })
        .catch((err) => {
          console.log(`[🆘] Error in Paradise ! `);
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <ul className="pl-0">
              <li>
                <Link to="/">
                  <img src={Logo} alt="mart48" className="footerLogo" />
                </Link>
              </li>
              <li>Contact Us</li>
              <li>
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </li>
              <li>
                <a href={`tel:${contactMobile}`}>{contactMobile}</a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 column-eq">
            <ul>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/#contact">Contact</Link>
              </li>
            </ul>
          </div>
          {socialPages ? (
            <div className="col-md-4 column-eq social-section">
              <ul className="mb-0">
                <li>Social Connects</li>
              </ul>
              <ul className="social-icons">
                {socialPages.facebook && (
                  <li>
                    <a href={socialPages.facebook}>
                      <img src={Facebook} alt={socialPages.facebook} />
                    </a>
                  </li>
                )}
                {socialPages.twitter && (
                  <li>
                    <a href={socialPages.twitter}>
                      <img src={Twitter} alt={socialPages.twitter} />
                    </a>
                  </li>
                )}
                {socialPages.instagram && (
                  <li>
                    <a href={socialPages.instagram}>
                      <img src={Pintrest} alt={socialPages.instagram} />
                    </a>
                  </li>
                )}
                {socialPages.linkedin && (
                  <li>
                    <a href={socialPages.linkedin}>
                      <img src={Linkedin} alt={socialPages.linkedin} />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Footer;
