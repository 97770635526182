import React from "react";
import Slider2 from "./slider2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faShoppingCart,
  faShareAlt,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import HowWorksList from "./howworkslist";
const HowItWorks = () => {
  const faStoreIcon = <FontAwesomeIcon icon={faStore} size="1x" color="#fff" />;
  const faCart = (
    <FontAwesomeIcon icon={faShoppingCart} size="1x" color="#ffffff" />
  );
  const faShare = (
    <FontAwesomeIcon icon={faShareAlt} size="1x" color="#ffffff" />
  );
  const faComment = (
    <FontAwesomeIcon icon={faCommentAlt} size="1x" color="#ffffff" />
  );

  return (
    <div className="container">
      <div className="row" id="how-it-works">
        <div className="col-md-6 slider-section">
          <Slider2 />
        </div>
        <div className="col-md-6 work-description-section">
          <div className="list-wrapper">
            <h3 className="sectionHeading">
              <span className="purpal-color">How</span> It Works
            </h3>
            <ul className="process-wrapper">
              <HowWorksList
                icon={faStoreIcon}
                heading="Everyday Essentials"
                description="Get fresh milk, breads etc delivered to you doorstep every morning."
              />
              <HowWorksList
                icon={faCart}
                heading="4000+ Products"
                description="Browse wide range of dairy as well as gricery products."
              />
              <HowWorksList
                icon={faShare}
                heading="Quick and Easy"
                description="Create quick subscribtion for mothly or custom date and get it delivered on every morning."
              />
              <HowWorksList
                icon={faComment}
                heading="Quick summary"
                description="Track your orders and subscriptions with our super easy and rich interface."
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
