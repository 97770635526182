import React from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import OwlCarousel from 'react-owl-carousel';
import '../node_modules/owl.carousel/dist/assets/owl.carousel.css'
import '../node_modules/owl.carousel/dist/assets/owl.theme.default.css'
import Milk from './images/milk.png'
import DairyProducts from './images/dairy-food.png'
import Breads from './images/breads-bakery.png'
import SweetsChocolates from './images/sweets-chocolates.png'
import Fruits from './images/fruits.png'
import Vegatables from './images/vegatables.png'
import CategoryWrapper from './categories-wrapper'
import ProductItems from './productitems'

const ProductTabs =()=>{
    const owlSlider = {
        margin: 10,
        nav: true,
        items:6,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:6
            }
        }        
    }
    return(
        <div className="container" id="products">            
            <div className="row">                
                <div className="col-md-12">                    
                    <Tabs defaultActiveKey="Products" id="uncontrolled-tab-example">
                        <Tab eventKey="Products" title="Products">                                
                            <OwlCarousel className='owl-theme' loop={true} margin={owlSlider.margin} nav={owlSlider.nav} items={owlSlider.items} autoplay={true} dots={false} responsiveClass={true} responsive={owlSlider.responsive}>
                                <ProductItems image={Milk} item="Milk"/>
                                <ProductItems image={DairyProducts} item="Dairy Frozen Food"/>
                                <ProductItems image={Breads} item="Breads & Bakery"/>
                                <ProductItems image={SweetsChocolates} item="Sweets & Chocolates"/>
                                <ProductItems image={Fruits} item="Fruits"/>
                                <ProductItems image={Vegatables} item="Vegatables"/>                                                      
                            </OwlCarousel>
                        </Tab>
                        <Tab eventKey="Categories" title="Categories">
                            <CategoryWrapper />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default ProductTabs