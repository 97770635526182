import React from 'react';

const ProductItems = (props)=>{
    return(
        <div className='item text-center'>
            <div className="content">
                <img src={props.image} className="mx-auto d-block"/>
                <h6>{props.item}</h6>
            </div>
        </div>
    )
}

export default ProductItems