import React from 'react'

const WhyMartItems = (props)=>{
    return(
       <div className="col-md-3 text-center">
           <img src={props.image}/>
           <h6 className="font-weight-bold">{props.heading}</h6>
           <p>{props.deliveryStatus}</p>           
       </div>
    )
}

export default WhyMartItems