import React from 'react'
import Navbar from './Navbar'
import TopHeading from './TopHeading'
import Footer from './footer'
import PageNotFoundContent from './pageNotFound-content'

const PageNotFound=()=>{
    return(
        <>  
            <Navbar />
            <TopHeading heading="Page Not Found" />
            <PageNotFoundContent />
            <Footer />
        </>
    )
}

export default PageNotFound