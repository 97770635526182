import API_DEV from "./api-dev";
import API_LOCAL from "./api-local";
import API_PROD from "./api-prod";
const hostname = window.location.hostname;
const port = window.location.port;
let isLocalApi = port >= 3000;

const { NODE_ENV } = process.env;

if (NODE_ENV === "development") {
  console.log("port", port);
  console.log("isLocalApi 2", isLocalApi);
  console.log("hostname", hostname);
  console.log("process.env", process.env);
}

export const API = NODE_ENV === "development" ? API_DEV : API_PROD;

// export const API =
//   hostname === "localhost" && isLocalApi
//     ? API_PROD
//     : hostname === "localhost"
//     ? API_DEV
//     : API_PROD;
if (NODE_ENV === "development") {
  console.log("API", API);
}
