import React from 'react';
import Navbar from './Navbar'
import TopHeading from './TopHeading'
import OffersContent from './offers-content'
import Footer from './footer'

const Offers = ()=>{
    return(
        <>  
            <Navbar />
            <TopHeading heading="Terms &amp; Conditions" />
            <OffersContent />
            <Footer />
        </>        
    )
}

export default Offers