
import React from 'react';

const HowWorksList=(props)=>{
    return(
        <li>
            <span>{props.icon}</span>
            <div className="description">
                <h5>{props.heading}</h5>
                <p>{props.description}</p>
            </div>
        </li>
    )
}

export default HowWorksList
