import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Banner01 from "./images/banner01.jpg";
import Banner02 from "./images/banner02.jpg";
import Banner03 from "./images/banner03.jpg";
import Banner04 from "./images/banner04.jpg";
import PlayStore from "./images/play_store.png";

const Slider = () => {
  return (
    <div id="home">
      <Carousel>
        <Carousel.Item>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mart48&amp;hl=en">
            <img
              className="d-block w-100 banner-image"
              src={Banner04}
              alt="Second Slide"
            />
            {/* <Carousel.Caption className="second-slide">
                        <h1>Grocery Shopping...! <br/>we make it easy</h1>
                        <p>Free delivery on order above &#8377;800</p>
                        <a href="https://play.google.com/store/apps/details?id=com.mart48&amp;hl=en"><img src={PlayStore} className="google-play"/></a>
                    </Carousel.Caption> */}
          </a>
        </Carousel.Item>
        {/* <Carousel.Item>
          <img
            className="d-block w-100 banner-image"
            src={Banner01}
            alt="First Slide"
          />
          <Carousel.Caption className="first-slide">
            <h1>
              Download <span className="white-color">Mart48</span> App on your
              phone
            </h1>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mart48&amp;hl=en">
              <img src={PlayStore} className="google-play" />
            </a>
          </Carousel.Caption>
        </Carousel.Item> */}
        {/* <Carousel.Item>
                    <img className="d-block w-100 banner-image" src={Banner03} alt="Third Slide" />
                    <Carousel.Caption className="third-slide">            
                        <a href="https://play.google.com/store/apps/details?id=com.mart48&amp;hl=en"><img src={PlayStore} className="google-play"/></a>
                    </Carousel.Caption>
                </Carousel.Item> */}
      </Carousel>
    </div>
  );
};

export default Slider;
