
import Home from './Home'
import Faq from './faq'
import PrivacyPolicy from './privacy-policy'
import ContactLessDelivery from './contactless-delivery'
import Offers from './offers'
import PageNotFound from './page-not-found'
import './App.css';
import { Route, Switch } from 'react-router-dom';

function App() {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/faq" component={Faq} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      {/* <Route path="/contactless-delivery" component={ContactLessDelivery} /> */}
      <Route path="/terms" component={Offers} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}

export default App;
