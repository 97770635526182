import React from 'react'
import CategoryItem from './category-item'
import GrocceryStaple from './images/Groccery-Staples-Icon.png'
import PersonalCare from './images/personal-care.png'
import Juice from './images/juice.png'
import BreakfastDairy from './images/breakfast-dairy.png'
import HomeKitchen from './images/home-kitchen.png'
import HouseholdItems from './images/household-items.png'
import PetsCare from './images/pets-care.png'
import NoodleSouces from './images/noodle-souces.png'
import BabyKids from './images/baby-kids.png'
import Biscuites from './images/biscuites.png'


const CategoryWrapper = ()=>{
    return(
        <ul className="row CategoryWrapper list-style-none">
            <CategoryItem image={GrocceryStaple} categoryname="Groccery & Staples"/>
            <CategoryItem image={PersonalCare} categoryname="Personal Care"/>
            <CategoryItem image={Juice} categoryname="Beverages & Juice"/>
            <CategoryItem image={BreakfastDairy} categoryname="Breakfast & Dairy"/>
            <CategoryItem image={HomeKitchen} categoryname="Home & Kitchen"/>
            <CategoryItem image={HouseholdItems} categoryname="Household Items"/>
            <CategoryItem image={PetsCare} categoryname="Pets Care"/>
            <CategoryItem image={NoodleSouces} categoryname="Noodles & Souces"/>
            <CategoryItem image={BabyKids} categoryname="Baby & Kids"/>
            <CategoryItem image={Biscuites} categoryname="Biscuits & Snacks"/>
            <CategoryItem image={HouseholdItems} categoryname="Sweets & Chocolates"/>
            <CategoryItem image={PetsCare} categoryname="Pooja Needs"/>
            <CategoryItem image={NoodleSouces} categoryname="Pulses"/>
            <CategoryItem image={BabyKids} categoryname="Branded Foods"/>
            <CategoryItem image={Biscuites} categoryname="Health & Medicine"/>            
        </ul>
    )
}

export default CategoryWrapper