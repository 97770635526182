// const protocol = "http";
// const host = "localhost";
// const port = "6001";
// const trailUrl = "api";
const {
  REACT_APP_PROTOCOL,
  REACT_APP_HOST,
  REACT_APP_PORT,
  REACT_APP_TRAIL_URL,
} = process.env;

const protocol = REACT_APP_PROTOCOL;
const host = REACT_APP_HOST;
const port = REACT_APP_PORT;
const trailUrl = REACT_APP_TRAIL_URL;

const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}/`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}/${trailUrl}`;

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
