import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import PhoneInHand from './images/phone-in-hand.png'
import MobileSlider01 from './images/mobileslider01.png'
import MobileSlider02 from './images/mobileslider02.png'
import MobileSlider03 from './images/mobileslider03.png'
import MobileSlider04 from './images/mobileslider04.png'

const Slider2 = ()=>{
    return(
        <React.Fragment>
            <Carousel controls={false}>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={MobileSlider02}
                    alt="First slide2"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={MobileSlider01}
                        alt="Second slide"
                    />                   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"                    
                    src={MobileSlider04}
                    alt="Third slide"
                    />                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={MobileSlider03}
                    alt="First slide2"
                    />                    
                </Carousel.Item>                
            </Carousel>            
            
        </React.Fragment>
    )

    
}

export default Slider2