import React, { useEffect, useState } from "react";
import { ApiGetNoAuth } from "./helper/API/ApiData";
import Loader from "./loader";

const OffersContent = () => {
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ApiGetNoAuth("configurations")
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          const { configurations: { termsAndConditions = "" } = {} } =
            response.data.data[0];
          setTermsAndConditions(termsAndConditions);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="offers">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {isLoading && <Loader />}
            <p>{termsAndConditions}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffersContent;
