import React from 'react'

const Copyright = ()=>{
    return(
        <div className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className="mb-0 copyright-text">© 2021-2022 Mart. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Copyright