import React from 'react';
import Navbar from './Navbar'
import TopHeading from './TopHeading'
import FaqList from './faq-list'
import Footer from './footer'

const Faq = ()=>{
    return(
        <>  
            <Navbar />
            <TopHeading heading="Frequently Asked Questions" />
            <FaqList />
            <Footer />
        </>        
    )
}

export default Faq