import React from 'react'

const PageNotFoundContent = ()=>{
    return(
        <div className="pagenotfound">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>404. !! Page Not Found.</h2>                        
                        <p>Contact your site admin.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFoundContent