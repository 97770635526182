import React, { useState } from "react";
import { ApiPostNoAuth } from "./helper/API/ApiData";
import PlayStore from "./images/play_store.png";

const ContactForm = () => {
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleChange = (event) => {
    input[event.target.name] = event.target.value;
    if (submitted) {
      validate();
    }
    setInput(input);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }
    setSubmitted(true);
    if (validate()) {
      setLoading(true);
      ApiPostNoAuth("feedbacks", input)
        .then((res) => {
          alert("Thanks for contacting Mart48, we will get back to you soon.");
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
          let input = {};
          input["name"] = "";
          input["mobile"] = "";
          input["email"] = "";
          input["description"] = "";
          setInput(input);
        });
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your name.";
    }

    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your mobile.";
    }

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email address.";
    }

    if (!input["description"]) {
      isValid = false;
      errors["description"] = "Please enter your query.";
    }

    // validate email
    var patternEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
    );
    if (!patternEmail.test(input["email"])) {
      isValid = false;
      errors["email"] = "Please enter valid email address.";
    }

    // validate mobile
    var patternMobile = new RegExp(/^[6789]{1}\d{9}$/i);
    if (!patternMobile.test(input["mobile"])) {
      isValid = false;
      errors["mobile"] = "Please enter valid mobile.";
    }

    setErrors(errors);

    return isValid;
  };

  return (
    <div id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-9 ContactForm-Left">
            <h6>Download Free Mart App</h6>
            <a href="https://play.google.com/store/apps/details?id=com.mart48&amp;hl=en">
              <img src={PlayStore} />
            </a>
          </div>

          <div className="col-md-3 ContactForm-Right">
            <div className="form-section">
              <h6 className="text-center form-heading">Contact Us</h6>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Enter Name"
                    value={input.name}
                    onChange={handleChange}
                    name="name"
                    disabled={isLoading}
                  />
                  <div className="text-danger">{errors.name}</div>
                </div>
                <div className="form-group">
                  <input
                    type="phone"
                    placeholder="Enter Number"
                    value={input.mobile}
                    onChange={handleChange}
                    name="mobile"
                    disabled={isLoading}
                  />
                  <div className="text-danger">{errors.mobile}</div>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email ID"
                    value={input.email}
                    onChange={handleChange}
                    name="email"
                    disabled={isLoading}
                  />
                  <div className="text-danger">{errors.email}</div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Query"
                    value={input.description}
                    onChange={handleChange}
                    name="description"
                    disabled={isLoading}
                  />
                  <div className="text-danger">{errors.description}</div>
                </div>

                <div className="text-center">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
