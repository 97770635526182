import React from 'react';
import Navbar from './Navbar'
import TopHeading from './TopHeading'
import PrivacyContent from './privacy-content'
import Footer from './footer'

const PrivacyPolicy = ()=>{
    return(
        <>  
            <Navbar />
            <TopHeading heading="Privacy Policy" />
            <PrivacyContent />
            <Footer />
        </>        
    )
}

export default PrivacyPolicy