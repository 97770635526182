import React from 'react';
import Logo from './images/logo.png'
import './style.scss';
import * as ReactBootStrap from 'react-bootstrap'
import { Link } from 'react-router-dom';

const Navbar = ()=>{
    return(       
        <ReactBootStrap.Navbar expand="lg" fixed="top" className="nav_bg">
            <div className="container p-0">
                <ReactBootStrap.Navbar.Brand><Link to="/"><img src={Logo} width="100px"/></Link></ReactBootStrap.Navbar.Brand>
                <ReactBootStrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
                <ReactBootStrap.Navbar.Collapse id="basic-navbar-nav">
                    <ReactBootStrap.Nav defaultActiveKey="#home">
                        <ReactBootStrap.Nav.Link href="#home">Home</ReactBootStrap.Nav.Link>                        
                        <ReactBootStrap.Nav.Link href="#why-mart">Why Mart</ReactBootStrap.Nav.Link>
                        <ReactBootStrap.Nav.Link href="#products">Products</ReactBootStrap.Nav.Link>
                        <ReactBootStrap.Nav.Link href="#how-it-works">How it Works</ReactBootStrap.Nav.Link>                        
                        <ReactBootStrap.Nav.Link href="#contact">Contact Us</ReactBootStrap.Nav.Link>                                    
                    </ReactBootStrap.Nav>
                </ReactBootStrap.Navbar.Collapse>
            </div>
        </ReactBootStrap.Navbar>
    )
}

export default Navbar;