import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { ApiGetNoAuth } from "./helper/API/ApiData";
import Loader from "./loader";
// import 'font-awesome/css/font-awesome.min.css';

const FaqList = () => {
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ApiGetNoAuth("configurations")
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          const { faqs = [] } = response.data.data[0];
          setFaqs(faqs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="container FaqList">
      <div className="row">
        <div className="col-md-12">
          {isLoading && <Loader />}
          <Accordion>
            {faqs.map((item, index) => {
              return (
                <Card key={item.id}>
                  <Accordion.Toggle as={Card.Header} eventKey={`${index + 1}`}>
                    {item.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${index + 1}`}>
                    <Card.Body>{item.description}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FaqList;
