import React from "react";
import WhyMartItems from "./why-mart-wrapper-items";
import Subscription from "./images/subscription.png";
import Reliability from "./images/reliability.png";
import Easeofaccess from "./images/ease-of-access.png";
import EasyConnectivity from "./images/easy-connectivity.png";

const WhyMart = () => {
  return (
    <React.Fragment>
      <div id="why-mart">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-center sectionHeading">
                <span className="purpal-color">Why</span> Mart ?
              </h3>
              <div className="headingBorder text-center"></div>
              <div className="row">
                <WhyMartItems
                  heading="Subscription"
                  deliveryStatus="Daily delivery Before 7:00 AM"
                  image={Subscription}
                />
                <WhyMartItems
                  heading="Reliability"
                  deliveryStatus="Get fresh and quality products everyday"
                  image={Reliability}
                />
                <WhyMartItems
                  heading="Ease of Access"
                  deliveryStatus="Quick subscribtion for mothly or custom date"
                  image={Easeofaccess}
                />
                <WhyMartItems
                  heading="Easy Connectivity"
                  deliveryStatus="Track your orders and subscriptions"
                  image={EasyConnectivity}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhyMart;
