import React from 'react'

const CategoryItem = (props)=>{
    return(
        <li className='CategoryItem text-center'>
            <img src={props.image} />
            <p className="CategoryItem-des">{props.categoryname}</p>
        </li>
    )
}

export default CategoryItem