import React from 'react'

const TopHeading = (props)=>{
    return(
        <div className="TopHeading">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{props.heading}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeading