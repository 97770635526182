import React from 'react';
import Navbar from './Navbar'
import Slider from './Slider'
import WhyMart from './why-mart-wrapper'
import ProductTabs from './productTabs'
import HowItWorks from './HowItWorks'
import Achievements from './achievements'
import ContactForm from './contact-form'
import Footer from './footer'
import Copyright from './copyright'

const Home = ()=>{
    return(
        <>  
            <Navbar /> 
            <Slider />
            <WhyMart />
            <ProductTabs />
            <HowItWorks />           
            {/* <Achievements /> */}
            <ContactForm />
            <Footer /> 
            <Copyright />
        </>        
    )
}

export default Home